<template>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-form v-loading="loading" :model="form" label-width="12rem" style="padding: 2rem;">
          <el-form-item label="支付宝开放平台移动应用appid">
            <el-input v-model="form.appid"></el-input>
          </el-form-item>
          <el-form-item label="应用私钥">
            <el-input type="textarea" v-model="form.private_key"></el-input>
          </el-form-item>
          <el-form-item >
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>

      </el-col>
      <el-col :span="12">
        <el-form v-loading="pemLoading" label-width="8rem" style="padding: 2rem;">
          <el-form-item label="应用公钥证书">
            <y_upload_ali_cert @load="loadPem" mode="app_cert_public_key.crt" v-model="app_cert_public_key"></y_upload_ali_cert>
          </el-form-item>
          <el-form-item label="支付宝根证书">
            <y_upload_ali_cert @load="loadPem" mode="alipay_root_cert.crt" v-model="alipay_root_cert"></y_upload_ali_cert>
          </el-form-item>
          <el-form-item label="支付宝公钥证书">
            <y_upload_ali_cert @load="loadPem" mode="alipay_cert_public_key_rsa_2.crt" v-model="alipay_cert_public_key_rsa_2"></y_upload_ali_cert>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
</template>

<script>
import y_upload_ali_cert from "@/components/y_upload/y_upload_ali_cert";
export default {
  name: "aliPay",
  components: {y_upload_ali_cert},
  data(){
    return{
      form:{
        appid:"",
        private_key:"",
      },
      loading:false,
      payment_dir:"",
      pemLoading:false,
      app_cert_public_key:false,
      alipay_root_cert:false,
      alipay_cert_public_key_rsa_2:false,
    }
  },
  mounted() {
    this.load();
    this.loadPem();
  },
  methods:{
    loadPem(){
      this.pemLoading = true;
      this.$api.sys.manager.aliPayCert().then(res=>{
        this.pemLoading = false;
        this.app_cert_public_key = res.app_cert_public_key;
        this.alipay_root_cert = res.alipay_root_cert;
        this.alipay_cert_public_key_rsa_2 = res.alipay_cert_public_key_rsa_2;
      })
    },
    load(){
      this.loading = true;
      this.$api.sys.manager.aliPay().then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    submit(){
      this.$api.sys.manager.aliPayEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  line-height: 1rem
}
</style>